import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";

import Line from "./Line";

const ApexCharts = () => {
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Reports" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Reporting</h1>

        <Row>
          <Col lg="8">
            <Line />
          </Col>
          <Col lg="4">Select One of these reports</Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ApexCharts;
