import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../../utils/axios";

const EditJobModal = ({ show, handleClose, jobId }) => {
  const [jobData, setJobData] = useState({ title: "", description: "" });

  useEffect(() => {
    const fetchJobData = async () => {
      const response = await axios.get(`/api/jobs/${jobId}`);
      setJobData(response.data);
    };

    if (jobId) {
      fetchJobData();
    }
  }, [jobId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // ... Implement job editing logic
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={jobData.name}
              onChange={(e) =>
                setJobData({ ...jobData, title: e.target.value })
              }
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={jobData.description}
              onChange={(e) =>
                setJobData({ ...jobData, description: e.target.value })
              }
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Save changes
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditJobModal;
