import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "../../utils/axios";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import NewJobModal from "./NewJobModal";
import EditJobModal from "./EditJobModal";

import dragula from "react-dragula";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Lane = ({ name, children, onContainerLoaded, status }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.setAttribute("data-status", status);
      onContainerLoaded(containerRef.current);
    }
  }, [onContainerLoaded, status]);

  const isEmpty = !React.Children.count(children);

  return (
    <Card>
      <Card.Header>
        <Card.Title>{name}</Card.Title>
        <h6 className="card-subtitle text-muted">Job status.</h6>
      </Card.Header>
      <Card.Body>
        <div ref={containerRef}>
          {children}
          {isEmpty && <div className="placeholder">Drag cards here...</div>}
        </div>
        <div className="d-grid">
          <Button variant="primary">Add new task</Button>
        </div>
      </Card.Body>
    </Card>
  );
};

function formatDate(dateString) {
  let date = new Date(dateString);

  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
}

// Your component
const Task = ({ id, time, text, imageUrl, onView, status }) => (
  <Card
    className="mb-3 bg-light cursor-grab border"
    data-id={id}
    data-status={status}
  >
    <Card.Body className="p-3">
      <p>
        <strong>{formatDate(time)}</strong>
      </p>
      <p>{text}</p>
      {imageUrl && (
        <div className="float-end mt-n1">
          <img src={imageUrl} alt="Task" className="task-image" />
        </div>
      )}
      <Button variant="primary" size="md" onClick={() => onView(id)}>
        View
      </Button>
    </Card.Body>
  </Card>
);

const Jobs = () => {
  const dragulaRef = useRef(null);
  const [editJobId, setEditJobId] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [containers, setContainers] = useState([]);
  const [territory, setTerritory] = useState("");

  useEffect(() => {
    const savedTerritory = localStorage.getItem("territory");
    setTerritory(savedTerritory || "All Territories");
  }, []);

  const handleOpenNewModal = () => {
    setShowNewModal(true);
  };

  const handleCloseNewModal = () => {
    setShowNewModal(false);
  };

  const handleOpenEditModal = (jobId) => {
    setEditJobId(jobId);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  // Define fetchJobs as a useCallback so it can be used as a useEffect dependency
  const fetchJobs = useCallback(async () => {
    const jwtToken = localStorage.getItem("accessToken");
    const selectedTerritory = JSON.parse(
      localStorage.getItem("selectedTerritory")
    );
    let res;

    if (selectedTerritory) {
      res = await axios.get(`/api/jobs?territory=${selectedTerritory.value}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
    } else {
      res = await axios.get(`/api/jobs`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
    }

    setJobs(res.data);
  }, []);

  const updateJobsByTerritory = (event) => {
    if (event.key !== "selectedTerritory") {
      return;
    }

    fetchJobs();
  };

  useEffect(() => {
    window.addEventListener("storage", updateJobsByTerritory);

    return () => {
      window.removeEventListener("storage", updateJobsByTerritory);
    };
  }, [fetchJobs]); // Added dependency on fetchJobs

  useEffect(() => {
    fetchJobs();
  }, []);

  const onContainerReady = useCallback((container) => {
    containers.push(container);
  }, []);

  useEffect(() => {
    if (containers.length === 0) {
      return;
    }

    const drake = dragula(containers);

    drake.on("drop", async (el, target, source, sibling) => {
      const jobId = el.getAttribute("data-id");
      const newStatus = target.getAttribute("data-status");

      console.log("New Status: ", newStatus);

      setJobs(
        jobs.map((job) =>
          job.id === jobId ? { ...job, status: newStatus } : job
        )
      );

      const jwtToken = localStorage.getItem("accessToken");
      await axios.put(
        `/api/jobs/${jobId}`,
        { status: newStatus },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
    });

    return () => {
      drake.destroy();
    };
  }, [containers, jobs]);

  const jobsByStatus = jobs.reduce((groupedJobs, job) => {
    const { status } = job;
    if (!groupedJobs[status]) {
      groupedJobs[status] = [];
    }
    groupedJobs[status].push(job);
    return groupedJobs;
  }, {});

  const statuses = [
    "New Task",
    "Manager Review",
    "In Progress",
    "Completed",
    "Billed",
  ];

  return (
    <React.Fragment>
      <Helmet title="Jobs" />
      <Container fluid className="p-0">
        <Button
          variant="primary"
          className="float-end mt-n1"
          onClick={handleOpenNewModal}
        >
          <FontAwesomeIcon icon={faPlus} /> New job
        </Button>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h3">Jobs</h1>
          <h2 className="h5">{territory}</h2>
        </div>
        <NewJobModal show={showNewModal} handleClose={handleCloseNewModal} />
        <EditJobModal
          show={showEditModal}
          handleClose={handleCloseEditModal}
          jobId={editJobId}
        />
        <Row>
          {statuses.map((status) => (
            <Col key={status}>
              <Lane
                name={status}
                onContainerLoaded={onContainerReady}
                status={status}
              >
                {jobsByStatus[status] &&
                  jobsByStatus[status].map((job) => (
                    <Task
                      key={job.id}
                      id={job.id}
                      imageUrl={job.imageUrl}
                      time={job.time}
                      text={job.name}
                      onView={handleOpenEditModal}
                      status={status}
                    />
                  ))}
              </Lane>
            </Col>
          ))}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Jobs;
