import { useState } from "react";
import axios from "../utils/axios";

const useAddUserSubmitForm = () => {
  const [status, setStatus] = useState("idle");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (values) => {
    console.log("We are in the submit handler where values:", values);
    setStatus("loading");
    try {
      const response = await axios.post("/api/users/add", {
        values,
      });
      const data = await response.json();
      setData(data);
      setStatus("succeeded");
    } catch (error) {
      setError(error);
      setStatus("failed");
    }
  };

  return { status, data, error, handleSubmit };
};

export default useAddUserSubmitForm;
