import { useState, useEffect } from "react";
import axios from "../utils/axios";

const EmailList = () => {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const jwtToken = localStorage.getItem("accessToken");
        const tokenResponse = await axios.get(
          "/api/settings/get/AzureAccessToken",
          { headers: { Authorization: `Bearer ${jwtToken}` } }
        );
        const token = tokenResponse.data;

        const url =
          "https://graph.microsoft.com/v1.0/users/passmore@avhplumbingllc.com/messages";
        const config = {
          headers: { Authorization: `Bearer ${token}` },
          params: { $top: 10, $select: "subject,body,bodyPreview" },
        };

        const response = await axios.get(url, config);
        setEmails(response.data.value);
      } catch (error) {
        console.error("Failed to fetch emails:", error);
      }
    };

    fetchEmails();
  }, []);

  return (
    <div>
      <h1>Email List</h1>
      {emails.map((email, index) => (
        <div key={index}>
          <h2>{email.subject}</h2>
          <p>{email.body.content}</p> {/* Display the email body */}
        </div>
      ))}
    </div>
  );
};

export default EmailList;
