import React from "react";
import { Helmet } from "react-helmet-async";

import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";

const FormRow = () => (
  <Card>
    <Card.Header>
      <Card.Title>Add New Territory</Card.Title>
      <h6 className="card-subtitle text-muted">
        Enter the details of the new territory.
      </h6>
    </Card.Header>
    <Card.Body>
      <Form>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" placeholder="Email" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          <Form.Label>Address</Form.Label>
          <Form.Control type="text" name="address" placeholder="1234 Main St" />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Address 2</Form.Label>
          <Form.Control
            type="text"
            name="address2"
            placeholder="Apartment, studio, or floor"
          />
        </Form.Group>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" name="city" />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Select name="state">
                <option />
                <option>...</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group className="mb-3">
              <Form.Label>Zip</Form.Label>
              <Form.Control type="text" name="zip" />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            id="checkbox"
            label="Check me out"
            disabled
          />
        </Form.Group>
        <Button variant="primary">Submit</Button>
      </Form>
    </Card.Body>
  </Card>
);

const Layouts = () => (
  <React.Fragment>
    <Helmet title="Territories" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Territories</h1>

      <Row>
        <Col lg="12">
          <FormRow />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default Layouts;
