import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card } from "react-bootstrap";

import Header from "./Header";
import Statistics from "./Statistics";
import Map from "./Map";

import avatar1 from "../../assets/img/avatars/avatar.jpg";

const Default = () => (
  <React.Fragment>
    <Helmet title="Dashboard" />
    <Container fluid className="p-0">
      <Header />
      <Statistics />
      <Row>
        <Col lg="8">
          <Map />
        </Col>
        <Col lg="4">
          <Card>
            <Card.Header>
              <Card.Title className="mb-0">Worker Locations</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="d-flex">
                <img
                  src={avatar1}
                  width="36"
                  height="36"
                  className="rounded-circle me-2"
                  alt="Chris"
                />
                <div className="flex-grow-1">
                  <small className="float-end text-navy">5m ago</small>
                  <strong>Chris</strong> arrived at <strong>Store #2966</strong>
                  <br />
                  <small className="text-muted">Today 7:51 pm</small>
                  <br />
                </div>
              </div>
            </Card.Body>
            <Card.Body>
              <div className="d-flex">
                <img
                  src={avatar1}
                  width="36"
                  height="36"
                  className="rounded-circle me-2"
                  alt="Chris"
                />
                <div className="flex-grow-1">
                  <small className="float-end text-navy">5m ago</small>
                  <strong>Chris</strong> arrived at <strong>Store #2966</strong>
                  <br />
                  <small className="text-muted">Today 7:51 pm</small>
                  <br />
                </div>
              </div>
            </Card.Body>
            <Card.Body>
              <div className="d-flex">
                <img
                  src={avatar1}
                  width="36"
                  height="36"
                  className="rounded-circle me-2"
                  alt="Chris"
                />
                <div className="flex-grow-1">
                  <small className="float-end text-navy">5m ago</small>
                  <strong>Chris</strong> arrived at <strong>Store #2966</strong>
                  <br />
                  <small className="text-muted">Today 7:51 pm</small>
                  <br />
                </div>
              </div>
            </Card.Body>
            <Card.Body>
              <div className="d-flex">
                <img
                  src={avatar1}
                  width="36"
                  height="36"
                  className="rounded-circle me-2"
                  alt="Chris"
                />
                <div className="flex-grow-1">
                  <small className="float-end text-navy">5m ago</small>
                  <strong>Chris</strong> arrived at <strong>Store #2966</strong>
                  <br />
                  <small className="text-muted">Today 7:51 pm</small>
                  <br />
                </div>
              </div>
            </Card.Body>
            <Card.Body>
              <div className="d-flex">
                <img
                  src={avatar1}
                  width="36"
                  height="36"
                  className="rounded-circle me-2"
                  alt="Chris"
                />
                <div className="flex-grow-1">
                  <small className="float-end text-navy">5m ago</small>
                  <strong>Chris</strong> arrived at <strong>Store #2966</strong>
                  <br />
                  <small className="text-muted">Today 7:51 pm</small>
                  <br />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default Default;
