import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";

function TerritoriesDropdown() {
  const [territories, setTerritories] = useState([]);
  const [selectedTerritory, setSelectedTerritory] = useState(null);

  useEffect(() => {
    async function fetchTerritories() {
      try {
        const jwtToken = localStorage.getItem("accessToken");
        const response = await axios.get("/api/territories", {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });

        const formattedTerritories = response.data.map((territory) => ({
          value: territory.id,
          label: territory.name,
          color: territory.color,
        }));
        setTerritories(formattedTerritories);
      } catch (error) {
        console.error("There was an error fetching the territories: ", error);
      }
    }

    fetchTerritories();
  }, []);

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedTerritory(selectedOption);
    localStorage.setItem(
      "selectedTerritory",
      JSON.stringify(selectedOption.value)
    ); // Updating local storage with selected territory's id
  };

  const SingleValue = ({ data }) => (
    <div>
      <div
        style={{
          backgroundColor: data.color,
          width: "20px",
          height: "10px",
          display: "inline-block",
          marginRight: "8px",
        }}
      />
      {data.label}
    </div>
  );

  const ColourOption = ({ data, innerProps, label }) => {
    return (
      <div
        {...innerProps}
        style={{
          position: "relative",
          paddingLeft: "30px",
          cursor: "pointer",
        }}
      >
        {label}
        <span
          style={{
            position: "absolute",
            left: "5px",
            top: "50%",
            width: "20px",
            height: "10px",
            backgroundColor: data.color, // accessing color from data directly
            transform: "translateY(-50%)",
          }}
        />
      </div>
    );
  };

  const customStyles = {
    input: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  return (
    <div style={{ padding: "1.5rem 1.5rem 0.375rem" }}>
      Territory
      <Select
        styles={customStyles}
        options={territories}
        onChange={handleChange}
        value={selectedTerritory}
        components={{
          Option: ColourOption,
          SingleValue: SingleValue,
        }}
      />
    </div>
  );
}

export default TerritoriesDropdown;
