import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { Button } from "react-bootstrap";

function LoginMicrosoftButton() {
  const redirectToMicrosoft = () => {
    const microsoftAuthUrl =
      "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=" +
      process.env.REACT_APP_AZURE_CLIENT_ID +
      "&grant_type=authorization_code&response_type=code&redirect_uri=https://dispatch.avhplumbingllc.com/api/azure-oauth&response_mode=query&scope=openid%20offline_access%20user.read%20mail.read";

    /*
        const microsoftAuthUrl =
          "https://login.microsoftonline.com/98a85e04-a9d9-437c-98e9-c935e730b9d8/oauth2/v2.0/authorize?client_id=" +
          process.env.REACT_APP_AZURE_CLIENT_ID +
          "&response_type=code&redirect_uri=https://dispatch.avhplumbingllc.com/api/azure-oauth&response_mode=query&scope=offline_access%20user.read%20mail.read";
        */
    // Use history to redirect to the Microsoft OAuth2 endpoint
    window.location.href = microsoftAuthUrl;
  };

  return (
    <Button
      onClick={redirectToMicrosoft}
      variant="primary"
      className="me-1 mb-1"
    >
      <FontAwesomeIcon icon={faWindows} /> Login with Microsoft
    </Button>
  );
}

export default LoginMicrosoftButton;
