import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row } from "react-bootstrap";
import EmailList from "../../components/EmailList";

//Random change

const AdminEmails = () => (
  <React.Fragment>
    <Helmet title="Global Settings" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Passmore Emails</h1>

      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title className="mb-0">Passmore Emails</Card.Title>
            </Card.Header>
            <Card.Body>
              <p>Current emails at passmore@avhplumbingllc.com</p>
              <EmailList />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default AdminEmails;
