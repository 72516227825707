import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";

import Markers from "./Markers";

const GoogleMaps = () => (
  <React.Fragment>
    <Helmet title="View Territories" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">View Territories</h1>

      <Row>
        <Col>
          <Markers />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default GoogleMaps;
