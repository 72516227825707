import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";

import { Briefcase, Home, MapPin, MessageSquare } from "react-feather";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import avatar1 from "../../assets/img/avatars/avatar.jpg";
import avatar2 from "../../assets/img/avatars/avatar-2.jpg";
import avatar4 from "../../assets/img/avatars/avatar-4.jpg";
import avatar5 from "../../assets/img/avatars/avatar-5.jpg";

import unsplash1 from "../../assets/img/photos/unsplash-1.jpg";
import unsplash2 from "../../assets/img/photos/unsplash-2.jpg";

const ProfileDetails = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">Profile Details</Card.Title>
    </Card.Header>
    <Card.Body className="text-center">
      <img
        src={avatar1}
        alt="Chris H"
        className="img-fluid rounded-circle mb-2"
        width="128"
        height="128"
      />
      <Card.Title className="mb-0">Chris H</Card.Title>
      <div className="text-muted mb-2">Field Worker</div>

      <div>
        <Button size="sm" variant="primary">
          <MessageSquare width={16} height={16} /> Message
        </Button>
      </div>
    </Card.Body>

    <hr className="my-0" />

    <hr className="my-0" />
    <Card.Body>
      <Card.Title>Current Status</Card.Title>
      <ul className="list-unstyled mb-0">
        <li className="mb-1">Active</li>
      </ul>
    </Card.Body>
    <hr className="my-0" />
    <Card.Body>
      <Card.Title>Contact Info</Card.Title>

      <ul className="list-unstyled mb-0">
        <li className="mb-1">
          <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-1" />
          <Link to="/dashboard/default">chris@avhplumbingllc.com</Link>
        </li>
        <li className="mb-1">
          <FontAwesomeIcon icon={faTwitter} fixedWidth className="me-1" />
          <Link to="/dashboard/default">(813)999-1234)</Link>
        </li>
      </ul>
    </Card.Body>
  </Card>
);

const Activities = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">Notes</Card.Title>
    </Card.Header>
    <Card.Body>
      <div className="d-flex">
        <img
          src={avatar1}
          width="36"
          height="36"
          className="rounded-circle me-2"
          alt="Ashley Briggs"
        />
        <div className="flex-grow-1">
          <small className="float-end text-navy">5m ago</small>
          <strong>From: Chris H</strong>: Are you in route? <br />
          <small className="text-muted">Today 7:51 pm</small>
          <br />
        </div>
      </div>
      <hr />
      <div className="d-flex">
        <img
          src={avatar1}
          width="36"
          height="36"
          className="rounded-circle me-2"
          alt="Ashley Briggs"
        />
        <div className="flex-grow-1">
          <small className="float-end text-navy">5m ago</small>
          <strong>From: Chris H</strong>: Are you in route? <br />
          <small className="text-muted">Today 7:51 pm</small>
          <br />
        </div>
      </div>
      <hr />
      <div className="d-flex">
        <img
          src={avatar1}
          width="36"
          height="36"
          className="rounded-circle me-2"
          alt="Ashley Briggs"
        />
        <div className="flex-grow-1">
          <small className="float-end text-navy">5m ago</small>
          <strong>From: Chris H</strong>: Are you in route? <br />
          <small className="text-muted">Today 7:51 pm</small>
          <br />
        </div>
      </div>
      <hr />
      <div className="d-flex">
        <img
          src={avatar1}
          width="36"
          height="36"
          className="rounded-circle me-2"
          alt="Ashley Briggs"
        />
        <div className="flex-grow-1">
          <small className="float-end text-navy">5m ago</small>
          <strong>From: Chris H</strong>: Are you in route? <br />
          <small className="text-muted">Today 7:51 pm</small>
          <br />
        </div>
      </div>
      <hr />
      <div className="d-flex">
        <img
          src={avatar1}
          width="36"
          height="36"
          className="rounded-circle me-2"
          alt="Ashley Briggs"
        />
        <div className="flex-grow-1">
          <small className="float-end text-navy">5m ago</small>
          <strong>From: Chris H</strong>: Are you in route? <br />
          <small className="text-muted">Today 7:51 pm</small>
          <br />
        </div>
      </div>
      <hr />
      <div className="d-flex">
        <img
          src={avatar1}
          width="36"
          height="36"
          className="rounded-circle me-2"
          alt="Ashley Briggs"
        />
        <div className="flex-grow-1">
          <small className="float-end text-navy">5m ago</small>
          <strong>From: Chris H</strong>: Are you in route? <br />
          <small className="text-muted">Today 7:51 pm</small>
          <br />
        </div>
      </div>
      <hr />
      <div className="d-grid">
        <Button variant="primary">Load more</Button>
      </div>
    </Card.Body>
  </Card>
);

const Profile = () => (
  <React.Fragment>
    <Helmet title="Profile" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Profile</h1>

      <Row>
        <Col md="4" xl="3">
          <ProfileDetails />
        </Col>
        <Col md="8" xl="9">
          <Activities />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default Profile;
