import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import axios from "axios";

// User reducer
const userReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_USER":
      return action.payload;
    default:
      return state;
  }
};

// Notifications reducer
const notificationsReducer = (state = [], action) => {
  switch (action.type) {
    case "ADD_NOTIFICATION":
      return [...state, action.payload];
    default:
      return state;
  }
};

// Root reducer combining user and notifications reducers
const rootReducer = {
  user: userReducer,
  notifications: notificationsReducer,
};

// Settings reducer
const settingsReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_SETTINGS":
      return action.payload;
    default:
      return state;
  }
};

// Create Redux store with thunk middleware and rootReducer combined with settingsReducer
const store = configureStore({
  reducer: {
    ...rootReducer,
    settings: settingsReducer,
  },
  middleware: [thunk],
});

// Action creator that returns a thunk to perform the Axios call
const fetchSettings = () => {
  return async (dispatch) => {
    try {
      const jwtToken = localStorage.getItem("accessToken");
      const response = await axios.get("/api/settings", {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      dispatch({ type: "SET_SETTINGS", payload: response.data });
    } catch (error) {
      console.error("Error fetching settings", error);
    }
  };
};

// Dispatch the fetchSettings action
store.dispatch(fetchSettings());

export default store;
