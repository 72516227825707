import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import useAddUserSubmitForm from "../../../../hooks/useAddUserSubmitForm";

const FormRow = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { handleSubmit: submitForm } = useAddUserSubmitForm();

  return (
    <Card>
      <Card.Header>
        <Card.Title>Add User</Card.Title>
        <h6 className="card-subtitle text-muted">
          Fill out the basic details for a user of AVH Dispatch
        </h6>
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            user_role_id: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().required("First Name is required"),
            last_name: Yup.string().required("Last Name is required"),
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            user_role_id: Yup.string().required("Role is required"),
            password: Yup.string().required("Password is required"),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              await submitForm(values);
              enqueueSnackbar("User successfully created!", {
                variant: "success",
              }); // Show success notification
              navigate("/admin/users");
            } catch (error) {
              enqueueSnackbar("An error occurred while creating the user", {
                variant: "error",
              }); // Show error notification
            } finally {
              resetForm();
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="first_name" className="mb-2">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="first_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultValue=""
                  isInvalid={touched.first_name && errors.first_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.first_name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="last_name" className="mb-2">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultValue=""
                  isInvalid={touched.last_name && errors.last_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.last_name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="email" className="mb-2">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultValue=""
                  isInvalid={touched.email && errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="user_role_id" className="mb-2">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  name="user_role_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultValue=""
                  isInvalid={touched.user_role_id && errors.user_role_id}
                >
                  <option value="">Select Role</option>
                  <option value="1">Admin</option>
                  <option value="2">Worker</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.user_role_id}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="password" className="mb-2">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="text"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultValue=""
                  isInvalid={touched.password && errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <Button variant="primary" type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

const AddUser = () => (
  <React.Fragment>
    <Helmet title="Add User" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Add User</h1>

      <Row>
        <Col lg="12">
          <FormRow />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default AddUser;
