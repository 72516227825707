import {
  Bell,
  Box,
  BookOpen,
  Calendar,
  CheckSquare,
  Grid,
  Heart,
  Layout,
  List,
  Mail,
  PieChart,
  Sliders,
  DollarSign,
  PlusCircle,
  MapPin,
  Users,
  Settings,
  Share,
  Map,
  Trello,
} from "react-feather";

const operationsSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
  },
  {
    href: "/jobs",
    icon: Trello,
    title: "Jobs",
  },
  {
    href: "/territories",
    icon: Map,
    title: "Territories",
    children: [
      {
        href: "/territories",
        title: "View All Territories",
      },
      {
        href: "/territories/add",
        title: "Add Territory",
      },
    ],
  },
  {
    href: "/locations",
    icon: MapPin,
    title: "Locations",
  },
];

const inventorySection = [
  {
    href: "/inventory",
    icon: Box,
    title: "Inventory",
  },
  {
    href: "/inventory/add",
    icon: PlusCircle,
    title: "Create New Item",
  },
];

const invoicingSection = [
  {
    href: "/invoices",
    icon: DollarSign,
    title: "Invoices",
  },
  {
    href: "/invoices/add",
    icon: PlusCircle,
    title: "Create New Invoice",
  },
];

const reportingSection = [
  {
    href: "/reports",
    icon: PieChart,
    title: "Reports",
  },
];

const adminSection = [
  {
    href: "/admin",
    icon: Map,
    title: "Admin",
    children: [
      {
        href: "/admin/users",
        icon: Users,
        title: "Manage Users",
      },
      {
        href: "/admin/settings",
        icon: Settings,
        title: "Global Settings",
      },
      {
        href: "/admin/emails",
        icon: Mail,
        title: "Passmore Emails",
      },
    ],
  },
];

const navItems = [
  {
    title: "Operations",
    pages: operationsSection,
  },
  {
    title: "Inventory",
    pages: inventorySection,
  },
  {
    title: "Invoices",
    pages: invoicingSection,
  },
  {
    title: "Reports",
    pages: reportingSection,
  },
  {
    title: "Admin",
    pages: adminSection,
  },
];

export default navItems;
