import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row } from "react-bootstrap";
import LoginMicrosoftButton from "../settings/LoginMicrosoftButton";

const AdminSettings = () => (
  <React.Fragment>
    <Helmet title="Global Settings" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Global Settings</h1>

      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title className="mb-0">
                Settings for the AVH Dispatch App
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <p>
                Don't click this button unless you want to start pulling emails
                from a completely different mailbox and not
                passmore@avhplumbingllc.com.
              </p>
              <LoginMicrosoftButton />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default AdminSettings;
