import React from "react";

const SidebarFooter = () => {
  return (
    <div className="sidebar-cta">
      <div className="sidebar-cta-content">
        <strong className="d-inline-block mb-2">Need Help?</strong>
        <div className="mb-3 text-sm">
          Contact the support team for AVH Plumbing Dispatch.
        </div>

        <div className="d-grid">
          <a
            href="https://support.avhplumbingllc.com/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
          >
            Support
          </a>
        </div>
      </div>
    </div>
  );
};

export default SidebarFooter;
