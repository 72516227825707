// ExpandableRows demo
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const subRows = [
  {
    name: "Shad Decker",
    position: "Regional Director",
    office: "Edinburgh",
    age: 51,
    startDate: "2008/11/13",
    salary: "$183,000",
  },
  {
    name: "Michael Bruce",
    position: "Javascript Developer",
    office: "Singapore",
    age: 29,
    startDate: "2011/06/27",
    salary: "$183,000",
  },
  {
    name: "Donna Snider",
    position: "Customer Support",
    office: "New York",
    age: 27,
    startDate: "2011/01/25",
    salary: "$112,000",
  },
];

const tableData = [
  {
    name: "Tiger Nixon",
    position: "System Architect",
    office: "Edinburgh",
    age: 61,
    startDate: "2011/04/25",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Garrett Winters",
    position: "Accountant",
    office: "Tokyo",
    age: 63,
    startDate: "2011/07/25",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Ashton Cox",
    position: "Junior Technical Author",
    office: "San Francisco",
    age: 66,
    startDate: "2009/01/12",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Cedric Kelly",
    position: "Senior Javascript Developer",
    office: "Edinburgh",
    age: 22,
    startDate: "2012/03/29",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Airi Satou",
    position: "Accountant",
    office: "Tokyo",
    age: 33,
    startDate: "2008/11/28",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Brielle Williamson",
    position: "Integration Specialist",
    office: "New York",
    age: 61,
    startDate: "2012/12/02",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Herrod Chandler",
    position: "Sales Assistant",
    office: "San Francisco",
    age: 59,
    startDate: "2012/08/06",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Rhona Davidson",
    position: "Integration Specialist",
    office: "Tokyo",
    age: 55,
    startDate: "2010/10/14",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Colleen Hurst",
    position: "Javascript Developer",
    office: "San Francisco",
    age: 39,
    startDate: "2009/09/15",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Sonya Frost",
    position: "Software Engineer",
    office: "Edinburgh",
    age: 23,
    startDate: "2008/12/13",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Jena Gaines",
    position: "Office Manager",
    office: "London",
    age: 30,
    startDate: "2008/12/19",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Quinn Flynn",
    position: "Support Lead",
    office: "Edinburgh",
    age: 22,
    startDate: "2013/03/03",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Charde Marshall",
    position: "Regional Director",
    office: "San Francisco",
    age: 36,
    startDate: "2008/10/16",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Haley Kennedy",
    position: "Senior Marketing Designer",
    office: "London",
    age: 43,
    startDate: "2012/12/18",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Tatyana Fitzpatrick",
    position: "Regional Director",
    office: "London",
    age: 19,
    startDate: "2010/03/17",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Michael Silva",
    position: "Marketing Designer",
    office: "London",
    age: 66,
    startDate: "2012/11/27",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Paul Byrd",
    position: "Chief Financial Officer (CFO)",
    office: "New York",
    age: 64,
    startDate: "2010/06/09",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Gloria Little",
    position: "Systems Administrator",
    office: "New York",
    age: 59,
    startDate: "2009/04/10",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Bradley Greer",
    position: "Software Engineer",
    office: "London",
    age: 41,
    startDate: "2012/10/13",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Dai Rios",
    position: "Personnel Lead",
    office: "Edinburgh",
    age: 35,
    startDate: "2012/09/26",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Jenette Caldwell",
    position: "Development Lead",
    office: "New York",
    age: 30,
    startDate: "2011/09/03",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Yuri Berry",
    position: "Chief Marketing Officer (CMO)",
    office: "New York",
    age: 40,
    startDate: "2009/06/25",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Caesar Vance",
    position: "Pre-Sales Support",
    office: "New York",
    age: 21,
    startDate: "2011/12/12",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Doris Wilder",
    position: "Sales Assistant",
    office: "Sidney",
    age: 23,
    startDate: "2010/09/20",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Angelica Ramos",
    position: "Chief Executive Officer (CEO)",
    office: "London",
    age: 47,
    startDate: "2009/10/09",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Gavin Joyce",
    position: "Developer",
    office: "Edinburgh",
    age: 42,
    startDate: "2010/12/22",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Jennifer Chang",
    position: "Regional Director",
    office: "Singapore",
    age: 28,
    startDate: "2010/11/14",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Brenden Wagner",
    position: "Software Engineer",
    office: "San Francisco",
    age: 28,
    startDate: "2011/06/07",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Fiona Green",
    position: "Chief Operating Officer (COO)",
    office: "San Francisco",
    age: 48,
    startDate: "2010/03/11",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Shou Itou",
    position: "Regional Marketing",
    office: "Tokyo",
    age: 20,
    startDate: "2011/08/14",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Michelle House",
    position: "Integration Specialist",
    office: "Sidney",
    age: 37,
    startDate: "2011/06/02",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Suki Burks",
    position: "Developer",
    office: "London",
    age: 53,
    startDate: "2009/10/22",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Prescott Bartlett",
    position: "Technical Author",
    office: "London",
    age: 27,
    startDate: "2011/05/07",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Gavin Cortez",
    position: "Team Leader",
    office: "San Francisco",
    age: 22,
    startDate: "2008/10/26",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Martena Mccray",
    position: "Post-Sales support",
    office: "Edinburgh",
    age: 46,
    startDate: "2011/03/09",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Unity Butler",
    position: "Marketing Designer",
    office: "San Francisco",
    age: 47,
    startDate: "2009/12/09",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Howard Hatfield",
    position: "Office Manager",
    office: "San Francisco",
    age: 51,
    startDate: "2008/12/16",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Hope Fuentes",
    position: "Secretary",
    office: "San Francisco",
    age: 41,
    startDate: "2010/02/12",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Vivian Harrell",
    position: "Financial Controller",
    office: "San Francisco",
    age: 62,
    startDate: "2009/02/14",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Timothy Mooney",
    position: "Office Manager",
    office: "London",
    age: 37,
    startDate: "2008/12/11",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Jackson Bradshaw",
    position: "Director",
    office: "New York",
    age: 65,
    startDate: "2008/09/26",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Olivia Liang",
    position: "Support Engineer",
    office: "Singapore",
    age: 64,
    startDate: "2011/02/03",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Bruno Nash",
    position: "Software Engineer",
    office: "London",
    age: 38,
    startDate: "2011/05/03",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Sakura Yamamoto",
    position: "Support Engineer",
    office: "Tokyo",
    age: 37,
    startDate: "2009/08/19",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Thor Walton",
    position: "Developer",
    office: "New York",
    age: 61,
    startDate: "2013/08/11",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Finn Camacho",
    position: "Support Engineer",
    office: "San Francisco",
    age: 47,
    startDate: "2009/07/07",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Serge Baldwin",
    position: "Data Coordinator",
    office: "Singapore",
    age: 64,
    startDate: "2012/04/09",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Zenaida Frank",
    position: "Software Engineer",
    office: "New York",
    age: 63,
    startDate: "2010/01/04",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Zorita Serrano",
    position: "Software Engineer",
    office: "San Francisco",
    age: 56,
    startDate: "2012/06/01",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Jennifer Acosta",
    position: "Junior Javascript Developer",
    office: "Edinburgh",
    age: 43,
    startDate: "2013/02/01",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Cara Stevens",
    position: "Sales Assistant",
    office: "New York",
    age: 46,
    startDate: "2011/12/06",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Hermione Butler",
    position: "Regional Director",
    office: "London",
    age: 47,
    startDate: "2011/03/21",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Lael Greer",
    position: "Systems Administrator",
    office: "London",
    age: 21,
    startDate: "2009/02/27",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Jonas Alexander",
    position: "Developer",
    office: "San Francisco",
    age: 30,
    startDate: "2010/07/14",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Shad Decker",
    position: "Regional Director",
    office: "Edinburgh",
    age: 51,
    startDate: "2008/11/13",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Michael Bruce",
    position: "Javascript Developer",
    office: "Singapore",
    age: 29,
    startDate: "2011/06/27",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
  {
    name: "Donna Snider",
    position: "Customer Support",
    office: "New York",
    age: 27,
    startDate: "2011/01/25",
    salary: (
      <Button href="/invoices/sample" variant="primary" className="me-1 mb-1">
        <FontAwesomeIcon icon={faEye} /> View
      </Button>
    ),
    subRows: subRows,
  },
];

const tableColumns = [
  {
    Header: "Item",
    accessor: "name",
  },
  {
    Header: "Short Description",
    accessor: "position",
  },
  {
    Header: "Warehouse",
    accessor: "office",
  },
  {
    Header: "Cost",
    accessor: "age",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
  },
  {
    Header: "Actions",
    accessor: "salary",
  },
];

export { tableData, tableColumns };
